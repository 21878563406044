import { Tooltip } from "@mui/material";

const ToggleButton = (props: any) => {
    const { click, checked, toolTip = false } = props;
    return <div
        onClick={() => click()}
        className={`w-8 h-4 flex items-center ${checked ? "bg-primary" : 'bg-primary-alpha-40'} rounded-full mx-3 px-1 bg-blue-700 cursor-pointer`}>
        {toolTip ?
            <Tooltip title={checked ? "Enabled" : "Disabled"} arrow>
                <div
                    className={` w-3 h-3 rounded-full shadow-md transform transition-transform ${checked
                        ? "translate-x-3 bg-ternary"
                        : "translate-x-0 bg-ternary-text"
                        }`}
                ></div>

            </Tooltip>

            :
            <div
                className={` w-3 h-3 rounded-full shadow-md transform transition-transform ${checked
                    ? "translate-x-3 bg-ternary"
                    : "translate-x-0 bg-[#898989]"
                    }`}
            ></div>


        }


    </div>
}
export default ToggleButton
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateIcon from "@mui/icons-material/Update";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import NotInterestedOutlinedIcon from "@mui/icons-material/NotInterestedOutlined";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Store } from "react-notifications-component";
import CircularProgress from "@mui/material/CircularProgress";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import regEx from "../../config/regex";
import Lottiefy from "../../atom/lottie/lottie";
import * as loaderLottie from "../../utils/lottie/loaderLottie.json";
interface ThemedButtonType {
  children: any;
  theme: "primary" | "secondary" | "ternary" | "error";
  icon?:
    | "save"
    | "update"
    | "cancel"
    | "custom"
    | "new"
    | "remove"
    | "unassign"
    | "download"
    | "upload"
    | "null"
    | "disable";
  rest?: any;
  iconComponent?: JSX.Element;
  loading?: true | false;
  disabled?: true | false;
  hidden?: true | false;
  valiData?: any;
  change?: any;
  classname?: any;
  click?: any;
}
const Icons: any = {
  save: <SaveIcon />,
  cancel: <CloseIcon />,
  update: <UpdateIcon />,
  remove: <DeleteIcon />,
  new: <AddIcon />,
  unassign: <RemoveCircleOutlineIcon />,
  download: <DownloadOutlinedIcon />,
  upload: <FileUploadOutlinedIcon />,
  disable: <NotInterestedOutlinedIcon />,
};
const ThemedButton = ({
  children,
  theme,
  valiData = [],
  change,
  iconComponent,
  click,
  icon = "null",
  loading = false,
  disabled = false,
  hidden = false,
  classname,
  ...rest
}: ThemedButtonType) => {
  const skipClick = () => {
    Store.addNotification({
      title: "Input Error",
      message: "No Click Functionality Implemented",
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: false,
      },
    });
  };
  const handleClick = () => {
    if (valiData) {
      let valiState = true;
      let msg = "";
      let dummy = [...valiData];
      let ObjectData: any = {};
      dummy.forEach((node: any) => {
        let regExTest = false;
        // debugger
        if (node?.regEx) {
          let res = node.regEx;
          regExTest = !res.test(node.value);
        } else {
          if (node?.type === "password") {
            let res = regEx.password;
            regExTest = !res.test(node.value);
          } else if (node?.type === "email") {
            let res = regEx.email;
            regExTest = !res.test(node.value);
          }
        }
        if (
          node.required === true &&
          node?.value?.length < node?.minLength &&
          (node.value !== "" ||
            regExTest ||
            (Array.isArray(node.value) && node.value.length !== 0)) &&
          (!node.hidden || node.hidden === false) &&
          (!node?.disabled || node.disabled === false)
        ) {
          if (valiState) {
            msg = `Minimum ${node?.minLength} Characters Required`;
            valiState = false;
            node.error = true;
          }
        }
        if (
          node.required === true &&
          (node.value === "" ||
            regExTest ||
            (Array.isArray(node.value) && node.value.length === 0)) &&
          (!node.hidden || node.hidden === false) &&
          (!node?.disabled || node.disabled === false)
        ) {
          if (valiState) {
            msg =
              msg + " " + node?.errormessage ? node.errormessage : node.title;
          }

          console.log("regExTest", regExTest, node.type);
          if (node.type === "password" && regExTest) {
            node.showHint = true;
          }
          valiState = false;
          node.error = true;
        } else {
          node.error = false;
        }
        if (node?.result?.key && Array.isArray(node.value)) {
          let val: any = [];
          node.value.forEach((n: any) => {
            if (n[node?.result.key]) {
              val.push(n[node?.result.key]);
            } else {
              val.push(n);
            }
          });
          ObjectData[node.name] = val;
        } else if (node?.result?.key && node.value[node?.result?.key]) {
          ObjectData[node.name] = node.value[node?.result?.key];
        } else {
          ObjectData[node.name] = node.value;
        }
        change([...dummy]);
      });
      // Controlling Main Click
      if (valiState) {
        console.log("msg", valiState);
        click(ObjectData);
      } else {
        Store.addNotification({
          title: "Input Error",
          message: msg,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: false,
          },
        });
      }
    } else {
      click();
    }
  };
  const { t } = useTranslation();
  return (
    <>
      {!hidden ? (
        <LoadingButton
          disabled={disabled || loading}
          startIcon={
            loading ? (
              <CircularProgress color={"error"} size={20} />
            ) : // <Lottiefy
            //   loop={true}
            //   json={loaderLottie}
            //   height={10}
            //   width={30}
            // />
            icon === "custom" ? (
              iconComponent
            ) : (
              Icons[icon] || ""
            )
          }
          size="large"
          onClick={() => (click ? handleClick() : skipClick())}
          className={`border ${classname} button-custom-${theme}`}
          style={{ border: "!red" }}
          color={disabled ? "info" : theme === "ternary" ? "secondary" : theme}
          {...rest}
        >
          <a className="capitalize"> {children}</a>
        </LoadingButton>
      ) : (
        ""
      )}
    </>
  );
};
export default ThemedButton;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useEffect } from "react";

interface CrumbsState {
  brudCrubName: any[];
}

const initialState: CrumbsState = {
  brudCrubName: [],
};

const breadCrumbs = createSlice({
  name: "BreadCrumbsList", // Use a different name for the slice
  initialState,
  reducers: {
    setBreadcrumbs: (state, action: PayloadAction<any[]>) => {
      state.brudCrubName = action.payload;
      console.log("brudCrubName", state.brudCrubName);
    },
  },
});

export const { setBreadcrumbs } = breadCrumbs.actions;
export default breadCrumbs.reducer;

import React, { useContext, useState } from "react";
import { useAuth } from "../authv2/authClient";
import ComponentMap from "../../atom/componentmap";
import ThemedButton from "../../atom/ThemedButton/themedButton";
import { activityState } from "../../config/constants";
import Logo from "../../utils/images/logo.png";
import Vaspianlogo from "../../utils/images/vaspianlogo.png";

import LockOpenIcon from "@mui/icons-material/LockOpen";
import Loader from "../loader";
import FadeIn from "react-fade-in";
const Loginv2 = (props: any) => {
  const auth = useAuth();
  const [loader, setLoader] = useState<boolean>(false);
  const [data, setData] = React.useState<any>([
    {
      name: "username",
      title: "Email",
      focus: true,
      required: true,
      autoFocus: true,
      value: "",
    },
    {
      name: "password",
      title: "Password",
      type: "password",
      //   autoFocus: true,
      required: true,
      value: "",
    },
  ]);
  const handleLogin = (payload: { username: string; password: string }) => {
    setLoader(true);
    auth.tryLogin(payload, (isAuthenticated: any) => {
      setLoader(false);
    });
  };

  return (
    <div className="w-full h-full flex bg-ternary">
      <div className="w-1/2 max-md:hidden p-24 relative md:flex flex-auto items-center justify-center h-full bg-[#252861]">
        {/* topleft square */}
        {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="101"
                height="93"
                viewBox="0 0 101 93"
                fill="none"
                className="absolute top-0 left-0"
            >
                <rect width="10.2458" height="10.2458" fill="#FABE7D" />
                <rect
                    y="16.5508"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    y="33.1018"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    y="49.6526"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    y="66.2034"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    y="82.7542"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="14.9746"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="14.9746"
                    y="16.5508"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="14.9746"
                    y="33.1018"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="14.9746"
                    y="49.6526"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="14.9746"
                    y="66.2034"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="14.9746"
                    y="82.7542"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="29.9492"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="29.9492"
                    y="16.5508"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="29.9492"
                    y="33.1018"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="29.9492"
                    y="49.6526"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="29.9492"
                    y="66.2034"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="29.9492"
                    y="82.7542"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="44.9238"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="44.9238"
                    y="16.5508"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="44.9238"
                    y="33.1018"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="44.9238"
                    y="49.6526"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="44.9238"
                    y="66.2034"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="44.9238"
                    y="82.7542"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="59.8982"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="59.8982"
                    y="16.5508"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="59.8982"
                    y="33.1018"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="59.8982"
                    y="49.6526"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="59.8982"
                    y="66.2034"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="59.8982"
                    y="82.7542"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="74.8728"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="74.8728"
                    y="16.5508"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="74.8728"
                    y="33.1018"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="74.8728"
                    y="49.6526"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="74.8728"
                    y="66.2034"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="74.8728"
                    y="82.7542"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="89.8474"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="89.8474"
                    y="16.5508"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="89.8474"
                    y="33.1018"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="89.8474"
                    y="49.6526"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="89.8474"
                    y="66.2034"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
                <rect
                    x="89.8474"
                    y="82.7542"
                    width="10.2458"
                    height="10.2458"
                    fill="#FABE7D"
                />
            </svg> */}
        {/* bottom right square */}
        {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="254"
                height="236"
                viewBox="0 0 254 236"
                fill="none"
                className="absolute bottom-0 right-0"
            >
                <g opacity="0.5">
                    <rect width="26" height="26" fill="#FABE7D" />
                    <rect y="42" width="26" height="26" fill="#FABE7D" />
                    <rect y="84" width="26" height="26" fill="#FABE7D" />
                    <rect y="126" width="26" height="26" fill="#FABE7D" />
                    <rect y="168" width="26" height="26" fill="#FABE7D" />
                    <rect y="210" width="26" height="26" fill="#FABE7D" />
                    <rect x="38" width="26" height="26" fill="#FABE7D" />
                    <rect x="38" y="42" width="26" height="26" fill="#FABE7D" />
                    <rect x="38" y="84" width="26" height="26" fill="#FABE7D" />
                    <rect x="38" y="126" width="26" height="26" fill="#FABE7D" />
                    <rect x="38" y="168" width="26" height="26" fill="#FABE7D" />
                    <rect x="38" y="210" width="26" height="26" fill="#FABE7D" />
                    <rect x="76" width="26" height="26" fill="#FABE7D" />
                    <rect x="76" y="42" width="26" height="26" fill="#FABE7D" />
                    <rect x="76" y="84" width="26" height="26" fill="#FABE7D" />
                    <rect x="76" y="126" width="26" height="26" fill="#FABE7D" />
                    <rect x="76" y="168" width="26" height="26" fill="#FABE7D" />
                    <rect x="76" y="210" width="26" height="26" fill="#FABE7D" />
                    <rect x="114" width="26" height="26" fill="#FABE7D" />
                    <rect x="114" y="42" width="26" height="26" fill="#FABE7D" />
                    <rect x="114" y="84" width="26" height="26" fill="#FABE7D" />
                    <rect x="114" y="126" width="26" height="26" fill="#FABE7D" />
                    <rect x="114" y="168" width="26" height="26" fill="#FABE7D" />
                    <rect x="114" y="210" width="26" height="26" fill="#FABE7D" />
                    <rect x="152" width="26" height="26" fill="#FABE7D" />
                    <rect x="152" y="42" width="26" height="26" fill="#FABE7D" />
                    <rect x="152" y="84" width="26" height="26" fill="#FABE7D" />
                    <rect x="152" y="126" width="26" height="26" fill="#FABE7D" />
                    <rect x="152" y="168" width="26" height="26" fill="#FABE7D" />
                    <rect x="152" y="210" width="26" height="26" fill="#FABE7D" />
                    <rect x="190" width="26" height="26" fill="#FABE7D" />
                    <rect x="190" y="42" width="26" height="26" fill="#FABE7D" />
                    <rect x="190" y="84" width="26" height="26" fill="#FABE7D" />
                    <rect x="190" y="126" width="26" height="26" fill="#FABE7D" />
                    <rect x="190" y="168" width="26" height="26" fill="#FABE7D" />
                    <rect x="190" y="210" width="26" height="26" fill="#FABE7D" />
                    <rect x="228" width="26" height="26" fill="#FABE7D" />
                    <rect x="228" y="42" width="26" height="26" fill="#FABE7D" />
                    <rect x="228" y="84" width="26" height="26" fill="#FABE7D" />
                    <rect x="228" y="126" width="26" height="26" fill="#FABE7D" />
                    <rect x="228" y="168" width="26" height="26" fill="#FABE7D" />
                    <rect x="228" y="210" width="26" height="26" fill="#FABE7D" />
                </g>
            </svg> */}
        {/* top right circle */}

        <svg
          width="312"
          height="202"
          viewBox="0 0 312 202"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute top-0 -right-24"
        >
          <circle
            opacity="0.5"
            cx="156"
            cy="46"
            r="136.5"
            stroke="#98A6DD"
            stroke-width="39"
          />
        </svg>
        {/* bottom left circle */}

        <svg
          width="192"
          height="193"
          viewBox="0 0 192 193"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute bottom-0 -left-16"
        >
          <circle
            opacity="0.5"
            cx="46"
            cy="146"
            r="126.5"
            stroke="#98A6DD"
            stroke-width="39"
          />
        </svg>
        {/* triangle1 */}

        {/* <svg
                width="210"
                height="190"
                viewBox="0 0 246 218"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute top-32 left-12"
            >
                <path
                    d="M104.813 11.5C112.896 -2.49999 133.104 -2.5 141.187 11.5L241.645 185.5C249.728 199.5 239.625 217 223.459 217H22.5411C6.37526 217 -3.72839 199.5 4.35451 185.5L104.813 11.5Z"
                    stroke="#FCD1A3"
                    stroke-width="2"
                />
            </svg> */}
        {/* triangle2 */}

        {/* <svg
                width="200"
                height="180"
                viewBox="0 0 223 246"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute top-16 left-36"
            >
                <path
                    d="M8.94373 22.2224C9.50903 6.06645 27.3516 -3.41906 41.0604 5.14847L211.441 111.631C225.15 120.198 224.443 140.393 210.169 147.981L32.7626 242.294C18.4885 249.883 1.3525 239.173 1.91781 223.017L8.94373 22.2224Z"
                    stroke="#FCD1A3"
                    stroke-width="2"
                />
            </svg> */}

        {/* group triangle */}
        {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="268"
                height="261"
                viewBox="0 0 268 261"
                fill="none"
                className="absolute -right-16"
            >
                <path
                    d="M131.549 187.148C116.98 180.143 115.459 159.993 128.81 150.879L183.777 113.357C197.129 104.243 215.34 113 216.557 129.12L221.569 195.483C222.786 211.603 206.096 222.996 191.528 215.99L131.549 187.148Z"
                    stroke="#FCD1A3"
                    stroke-width="2"
                />
                <path
                    d="M87.9219 163.097C71.7692 163.749 60.9671 146.672 68.478 132.357L99.3995 73.4235C106.91 59.1085 127.101 58.2924 135.743 71.9546L171.32 128.2C179.961 141.862 170.573 159.756 154.42 160.409L87.9219 163.097Z"
                    stroke="#FCD1A3"
                    stroke-width="2"
                />
            </svg> */}
        {/* <svg
              className="absolute inset-0 pointer-events-none"
              viewBox="0 0 960 540"
              width="100%"
              height="100%"
              preserveAspectRatio="xMidYMax slice"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                className="opacity-20"
                fill="none"
                stroke="currentColor"
                strokeWidth="100"
              >
                <circle r="234" cx="196" cy="23"></circle>
                <circle r="234" cx="790" cy="491"></circle>
              </g>
            </svg> */}
        {/* <svg
              className="absolute top-0 right-0 w-64 opacity-20"
              viewBox="0 0 220 192"
              fill="none"
            >
              <defs>
                <pattern
                  id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="220"
                height="192"
                fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
              ></rect>
            </svg> */}
        <div className="z-10 relative w-full max-w-2xl text-[#FFFFFF]">
          <FadeIn>
            <div className="text-4xl font-bold leading-none text-white">
              {/*
               */}
              <img src={Vaspianlogo} height={400} width={400}></img>
            </div>
            <div className="mt-10 text-[44px] font-light leading-[3rem] text-[#FEBA12]">
              Your Epic Choice Among
              <br /> Telecom Service Providers
            </div>
          </FadeIn>
        </div>
      </div>

      <div className="w-1/2 max-md:w-full text-xl relative sm:flex items-center justify-center max-md:justify-center bg-[#ffffff]">
        <div className=" right-0 w-full md:w-[520px] max-sm:p-10 p-20 ">
          <div className="  max-w-full  w-full">
            <div className=" max-w-full w-full mx-auto sm:mx-0">
              <FadeIn>
                <div className="text-[40px] font-bold leading-none text-[#000C39] text-center">
                  Sign in
                </div>
                <div
                  className={`flex  flex-col justify-center  w-full mt-2  h-[200px] transition-height duration-500 ease-in-out smallPicker`}
                >
                  <ComponentMap data={data} setData={setData} />
                </div>
                <div className="flex justify-between p-2 mt-2 ">
                  <div className="pt-3">
                    {/* <span className="cursor-pointer">Don’t have an account?</span> */}
                  </div>
                  <div>
                    <ThemedButton
                      children={"Login"}
                      loading={loader}
                      click={handleLogin}
                      valiData={data}
                      change={setData}
                      theme={"primary"}
                    />
                  </div>
                </div>
              </FadeIn>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loginv2;

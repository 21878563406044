import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import {
  CallRecords,
  DashboardIcon,
  DisplayGroups,
  LiveBoards,
  MyXToneIcon,
  Provision,
  Reports,
  TimeZoneRestriction,
} from "../utils/icons/defaultIcons";
// import PlanIcon from '../utils/icons/PlanIcon';
// import QualityIcon from '../utils/icons/QualityIcon';
// import PortfolioIcon from '../utils/icons/PortfolioIcon';
// import ReportIcon from '../utils/icons/ReportIcon';
// import FilesIcon from '../utils/icons/FilesIcon';
// import ResourceUtilizationIcon from '../utils/icons/ResourceUtilizationIcon';

export const LeftMenuItems = {
  control: [
    {
      item: "dashboard",
      path: "dashboard",
      displayName: "Dashboard",
      hidden: true,
      icon: <DashboardIcon />,
      permission: "",
    },
    {
      item: "my_xtone",
      path: "myxtone",
      displayName: "My XTone",
      icon: <MyXToneIcon />,
      permission: "",
    },
    {
      item: "call_recordings",
      path: "callrecordings",
      displayName: "Call Recordings",
      icon: <CallRecords />,
      permission: "",
      childrens: [
        {
          item: "call_recordings",
          path: "callsInProgress",
          displayName: "Calls in Progress",
          // permission: "call_recordings.calls_in_progress.get",
        },
        {
          item: "call_recordings",
          path: "callArchives",
          displayName: "Call Archives",
          // permission: "call_recordings.call_archives.get",
        },
        {
          item: "call_recordings",
          path: "manageExtension",
          displayName: "Manage Extension",
          // permission: "call_recordings.manage_extension.get",
        },
        {
          item: "call_recordings",
          path: "legacycallrecordings",
          displayName: "Legacy Call Recordings",
          permission: "call_recordings.leagcy_recordings.get",
        },
      ],
    },
    {
      item: "timeZoneRestriction",
      path: "timeZoneRestriction",
      displayName: "Time Zone Restriction",
      icon: <TimeZoneRestriction />,
      permission: "",
    },
    {
      item: "liveBoards",
      path: "liveBoards",
      displayName: "Live Boards",
      icon: <LiveBoards />,
      permission: "",
    },
    {
      item: "reports",
      path: "reports",
      displayName: "Reports",
      icon: <Reports />,
      permission: "",
      childrens: [
        {
          item: "callReports",
          path: "callReports",
          displayName: "Call Reports",
          permission: "reports.call_report.get",
        },
        {
          item: "callStatistics",
          path: "callStatistics",
          displayName: "Call Statistics",
          permission: "",
          // reports.call_statistics.get
        },
        // {
        //   item: "aboutReporting",
        //   path: "aboutReporting",
        //   displayName: "About Reporting",
        //   permission: "",
        //   // reports.about_reporting.get
        // },
        {
          item: "displayGroups",
          path: "displayGroups",
          displayName: "Display Groups",
          permission: "",
          // reports.display_groups.get
        },
        {
          item: "legacy_call_report",
          path: "legacycallreports",
          displayName: "Legacy Call Reports",
          permission: "reports.legacy_call_report.get",
        },
      ],
    },
    {
      item: "provision",
      path: "provision",
      displayName: "Provision",
      icon: <Provision />,
      permission: "",
      childrens: [
        {
          item: "provisioning",
          path: "provisioning",
          displayName: "Provisioning",
          permission: "provision.users.get",
        },
        {
          item: "tenants",
          path: "tenants",
          displayName: "Tenants",
          permission: "provision_tenants.get",
        },
        {
          item: "DID",
          path: "DID",
          displayName: "DID",
          permission: "provision.dids.get",
        },
      ],
    },
  ],
};
